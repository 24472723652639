<template lang="pug">
.ChatMessage
  .d-flex.message-container.mt-5.mx-5(:class ="`justify-${justify}`")
    .message-text(v-if='message.sentByCurrentUser') {{message.text}} 
    
    v-avatar.profile-picture(:class='message.sentByCurrentUser ? "ml-2" : "mr-2"' size='50')
      v-img.grey(:src='userImage'  @load="imageError = false" @error="imageError = true")
        template(v-slot:default v-if="showImagePlaceholder")
          v-row.fill-height.ma-0(align='center', justify='center')
            v-icon(color='white', size='30') mdi-account-circle
    p.message-text(v-if='!message.sentByCurrentUser' v-html="message.text")

</template>
<script>
import Vue from 'vue';
export default Vue.extend({
  props: ['matchId', 'message', 'justify', 'userImage'],
  data() {
    return {
      imageError: false,
    };
  },
  computed: {
    showImagePlaceholder() {
      return this.imageError || this.userImage === '' || !this.userImage;
    },
  },
  methods: {},
  watch: {
    message: {
      immediate: true,
      handler(message) {
        if (!message) return;

        this.$store.dispatch('messages/markMessageSeen', {
          matchId: this.matchId,
          message,
        });
      },
    },
  },
});
</script>

<style lang="sass">
@import "@/assets/style/main"
.message-text
  background-color: $message-background-gray
  border-radius: 25px
  padding: 20px
  width: 60%
  min-width: 220px
  font-size: 14px
  white-space: pre-line
  margin-bottom: 0 !important
</style>
