<template lang="pug">
v-col.py-0.messageContainer(cols="12" md="8" v-if="selectedMatch")
  v-card.messageChatContainer.d-flex.flex-column.overflow-hidden.pb-0(:disabled="selectedMatch.opponentRemoved")
    .chatHeader.flex-grow-0.flex-shrink-0
      v-toolbar( color="transparent" elevation="0")
        v-toolbar-title
          v-list-item
            v-list-item-content(v-if="isBusiness") 
              v-list-item-subtitle.text-overline.mb-0.gray--text.text--lighten-2.text-truncate {{selectedMatch.position.name }}
              v-list-item-title.text-h6.text-truncate {{candidateDisplayName}}
            v-list-item-content(v-else)
              v-list-item-subtitle.text-overline.mb-0.gray--text.text--lighten-2.text-truncate {{selectedMatch.position.company.name }}
              v-list-item-title.text-h6.text-truncate(v-if="selectedMatch.position.company")  {{selectedMatch.position.name }} 
      v-divider
    
    .messageScroll.flex-grow-1.flex-shrink-0.pb-4.px-2
      .div(v-for="(group, index) in groupedMessages")
        ChatDate.mt-6(:date="formatDate(new Date(group.day).toISOString())")
        chatMessage(v-for="(message, index) in group.messages" :message="message" :matchId="selectedMatch.id" :key="index" :justify="message.sentByCurrentUser ? 'end' : 'start'" :userImage='userImageForMessage(message)')
      
    .flex-grow-0
      v-divider
      .textarea-container
        v-textarea.no-underline.mb-0.pb-0.textarea-max-height(v-model="newMessage" filled background-color="white" placeholder="Deine Nachricht" hide-details rows="1" single-line autofocus :key="autoGrowHack" auto-grow)
          template(slot='append')
            div(style="width: 40px")
        v-btn.mt-0.pb-2.pt-1.text-field-button(text small :ripple="false" elevation='0' background-color="white" @click="sendMessage")
            v-icon(color='accent' elevation='0') mdi-send
        //- template(slot='append')
          
</template>

<script>
import { UserType } from '@/types/user';
import Vue from 'vue';
import chatMessage from '../../components/messages/ChatMessage.vue';
import ChatDate from '../../components/messages/ChatDate.vue';
import { formatDate } from '@/utilities/DateAndTime';
import { MatchesApi } from '@/api/modules/matches';
export default Vue.extend({
  components: { chatMessage, ChatDate },
  props: ['matches', 'selectedMatch'],
  data() {
    return {
      newMessage: '',
      autoGrowHack: false,
    };
  },
  computed: {
    groupedMessages() {
      const messages =
        this.$store.state.messages.messagesByMatch[this.selectedMatch?.id] ??
        [];

      return messages
        .reduce((groupedArray, item) => {
          const day = item.sentAt.setHours(12, 0, 0, 0);
          const index = groupedArray.findIndex((r) => r.day === day);

          if (index === -1) {
            groupedArray.push({
              day: day,
              messages: [item],
            });
          } else {
            groupedArray[index].messages.push(item);
          }

          return groupedArray;
        }, [])
        .sort((a, b) => a.day - b.day);
    },
    isBusiness() {
      return this.$store.state.user.isBusiness;
    },
    candidateDisplayName() {
      if (this.selectedMatch.candidate.anonymous) {
        return 'Anonym';
      } else {
        return `${this.selectedMatch.candidate.firstName} ${this.selectedMatch.candidate.lastName}`;
      }
    },
  },
  updated() {
    // whenever data changes and the component re-renders, this is called.
    this.$nextTick(() => this.scrollToEnd());
  },
  methods: {
    formatDate,
    scrollToEnd: function () {
      try {
        // scroll to the last message
        const el = this.$el?.getElementsByClassName('messageScroll')[0];

        if (el) {
          el.scrollTop = el.scrollHeight;
        }
      } catch (e) {
        console.log(e);
      }
    },
    sendMessage() {
      this.$store
        .dispatch('messages/sendMessage', {
          matchId: this.selectedMatch.id,
          text: this.newMessage,
          companyId: this.selectedMatch.position.company.id,
        })
        .then(() => (this.newMessage = ''));
    },
    userImageForMessage(message) {
      const isBusiness =
        this.$store.state.user.currentUser.type === UserType.BUSINESS;

      if (message.sentByCurrentUser) {
        return this.$store.state.user?.currentUser?.photoURL;
      } else if (isBusiness) {
        if (this.selectedMatch.opponentRemoved) {
          return '';
        }
        return this.selectedMatch.candidate?.photoURL;
      } else {
        if (this.selectedMatch.opponentRemoved) {
          return '';
        }
        return this.selectedMatch.position.recruiter?.photoURL;
      }
    },
    markMatchChatSeen() {
      const breakpoint = this.$vuetify.breakpoint.name;
      if (this.selectedMatch && breakpoint !== 'xs' && breakpoint !== 'sm') {
        MatchesApi().markConversationForMatchActive(
          this.selectedMatch.id,
          true
        );
      }
    },
  },
  created() {
    this.markMatchChatSeen();
  },
  watch: {
    selectedMatch() {
      this.markMatchChatSeen();
    },
  },
});
</script>
<style lang="sass">

.messageContainer
  position: relative !important
  width: calc(100% - 26px)
  height: calc(100vh - 200px)

  .chatHeader
    position: sticky
    background: #fff
    z-index: 1
    top: 0
    right: 0
    left: 0
@media only screen and (max-width: 1263px)
  .messageContainer
    height: calc(100vh - 250px)


.messageChatContainer

  height: calc(100vh - 200px)
  overflow-y: auto
  position: relative
@media only screen and (max-width: 1263px)
  .messageChatContainer
    height: calc(100vh - 220px)


.no-underline .v-input__control .v-input__slot::before, .no-underline .v-input__control .v-input__slot::after
  content: none !important
  border-radius: 10px

.messageScroll
  overflow-y: auto
  max-height: calc(100% - 140px)
</style>
