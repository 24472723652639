<template lang="pug">

v-card(min-height="70vh" :disabled="selectedMatch.opponentRemoved")
    div
      v-toolbar( color="transparent" elevation="0")

        v-list-item.pl-0(style="max-width: 90%")
            v-list-item-content(v-if="isBusiness") 
              v-list-item-subtitle.text-overline.mb-0.gray--text.text--lighten-2.text-truncate {{selectedMatch.position.name }}
              v-list-item-title.text-h6.text-truncate {{candidateDisplayName}}
            v-list-item-content(v-else)
              v-list-item-subtitle.text-overline.mb-0.gray--text.text--lighten-2.text-truncate {{selectedMatch.position.company.name }}
              v-list-item-title.text-h6.text-truncate  {{selectedMatch.position.name }}
        
        v-spacer
        v-btn(icon @click="$emit('closeModal')")
          v-icon mdi-close
      v-divider
    .v-card-text.messageScroll.flex-grow-1.pb-4.px-2
      .div(v-for="(group, index) in groupedMessages")
        ChatDate.mt-6(:date="formatDate(new Date(group.day).toISOString())")
        chatMessage(v-for="(message, index) in group.messages" :message="message" :key="index" :justify="message.sentByCurrentUser ? 'end' : 'start'" :userImage='userImageForMessage(message)' :matchId="selectedMatch.id")
    v-divider
    .textarea-container.pb-2
        v-textarea.no-underline.mb-0.textarea-max-height(ref="sendMessageRef" v-model="newMessage" :blurOnSubmit="false" @focus="scrollToEnd" @blur="scrollToEnd" filled background-color="white" placeholder="Deine Nachricht" hide-details rows="1" single-line :key="autoGrowHack" autofocus auto-grow :disabled="selectedMatch.opponentRemoved")
          template(slot='append')
            div(style="width: 40px")
        v-btn.mt-0.pb-2.pt-1.text-field-button(text small :ripple="false" elevation='0' background-color="white" @click.stop="sendMessage")
            v-icon(color='accent' elevation='0') mdi-send
    //-div
      v-textarea.mb-0.pb-2(v-model="newMessage" flat solo background-color="white" placeholder="Deine Nachricht" hide-details rows="1" single-line :key="autoGrowHack" auto-grow )
        template(slot='append')
          v-btn.mt-0.pb-2.pt-1.text-field-button(text small :ripple="false" elevation='0' background-color="white" @click="sendMessage")
            v-icon(color='accent' elevation='0') mdi-send
</template>

<script>
import { UserType } from '@/types/user';
import Vue from 'vue';
import chatMessage from './ChatMessage.vue';
import ChatDate from '../../components/messages/ChatDate.vue';
import { formatDate } from '@/utilities/DateAndTime';
import { MatchesApi } from '@/api/modules/matches';
export default Vue.extend({
  components: { chatMessage, ChatDate },
  props: ['matches', 'selectedMatch', 'closeModal', 'visible'],
  data() {
    return {
      newMessage: '',
      autoGrowHack: false,
    };
  },
  computed: {
    groupedMessages() {
      const messages =
        this.$store.state.messages.messagesByMatch[this.selectedMatch.id] ?? [];

      return messages
        .reduce((groupedArray, item) => {
          const day = item.sentAt.setHours(12, 0, 0, 0);
          const index = groupedArray.findIndex((r) => r.day === day);

          if (index === -1) {
            groupedArray.push({
              day: day,
              messages: [item],
            });
          } else {
            groupedArray[index].messages.push(item);
          }

          return groupedArray;
        }, [])
        .sort((a, b) => a.day - b.day);
    },
    messages() {
      const messages =
        this.$store.state.messages.messagesByMatch[this.selectedMatch.id];
      return messages ?? [];
    },
    isBusiness() {
      return this.$store.state.user.isBusiness;
    },
    candidateDisplayName() {
      if (this.selectedMatch.candidate.anonymous) {
        return 'Anonym';
      } else {
        return `${this.selectedMatch.candidate.firstName} ${this.selectedMatch.candidate.lastName}`;
      }
    },
  },
  updated() {
    // whenever data changes and the component re-renders, this is called.
    this.$nextTick(() => this.scrollToEnd());
  },
  methods: {
    formatDate,
    scrollToEnd: function () {
      // scroll to the last message
      const el = this.$el.getElementsByClassName('messageScroll')[0];

      if (el) {
        setTimeout(() => {
          el.scrollTop = el.scrollHeight;
        }, 50);
      }
    },
    sendMessage() {
      this.$refs.sendMessageRef.focus();
      this.$store
        .dispatch('messages/sendMessage', {
          matchId: this.selectedMatch.id,
          text: this.newMessage,
          companyId: this.selectedMatch.position.company.id,
        })
        .then(() => (this.newMessage = ''));
    },
    userImageForMessage(message) {
      const isBusiness =
        this.$store.state.user.currentUser.type === UserType.BUSINESS;

      if (message.sentByCurrentUser) {
        return this.$store.state.user.currentUser.photoURL;
      } else if (isBusiness) {
        if (this.selectedMatch.opponentRemoved) {
          return '';
        }
        return this.selectedMatch.candidate?.photoURL;
      } else {
        if (this.selectedMatch.opponentRemoved) {
          return '';
        }
        return this.selectedMatch.position?.recruiter?.photoURL;
      }
    },
  },
  created() {
    MatchesApi().markConversationForMatchActive(
      this.selectedMatch.id,
      this.visible
    );
  },
  watch: {
    visible(visible) {
      MatchesApi().markConversationForMatchActive(
        this.selectedMatch.id,
        visible
      );
    },
  },
});
</script>
<style lang="sass" scoped>
.textarea-max-height
  // line-height: normal !important
  // padding-bottom: 10px !important
.messageScroll
  // overflow-y: auto
  // height: 100%
  // max-height: calc(100% - 120px)

.mobileList

  // height: calc(100vh - 200px)
  // overflow-y: auto
  // position: relative
// @media only screen and (max-width: 1263px)
//   .mobileList
//     // height: calc(100vh - 220px)
//     overflow-y: hidden
</style>
