<template lang="pug">
    v-card.chatCard(:class="selected && $vuetify.breakpoint.mdAndUp ? 'hovered' : ''")
        v-avatar.count-avatar(v-if='unreadMessageCount > 0' color="#ff5a5a" size="23") 
          strong {{unreadMessageCount}}
        NotAvailableOverlay(v-if="match.opponentRemoved" :canDelete="true"  @click="archiveMatch(true)")
        v-list-item
          v-list-item-content.pb-0.pt-0
            .mt-0
              v-chip(small color="green" dark) 
                strong {{Math.round(match.score.percentage)}}% Match
            .text-overline.mb-0.gray--text.text--lighten-2.text-truncate
              | {{match.position.name}}
            v-list-item-title.text-h6.mt-0.text-truncate
              | {{candidateDisplayName}}
            //- v-list-item-subtitle match.
          v-list-item-avatar.mt-1(size='80')
            Avatar(:src="match.opponentRemoved ? '' : match.candidate.photoURL")
        
        v-divider.mt-3
        v-list-item
          v-list-item-subtitle(v-if="match.lastMessage && match.lastMessage.text") 
            | {{formatDate(new Date(match.lastMessage.sentAt).toISOString()) }}: 
            | {{match.lastMessage.text}}  
        v-divider
        //- v-chip.unread-msg-chip(v-if='unreadMessageCount > 0' color="red" avatar) 
        //-     span {{unreadMessageCount}}
        v-card-actions.pb-4
          v-btn.ml-2(outlined color="accent" small rounded=''  @click.stop="navigateTo")
            strong.px-2 Details
          v-spacer 
          v-btn(icon color="accent" @click.stop="archiveMatch(true)")
            v-icon mdi-delete-outline
</template>

<script>
import { formatDate } from '@/utilities/DateAndTime';
import NotAvailableOverlay from '@/components/messages/NotAvailableOverlay.vue';
import Avatar from '@/components/elements/Avatar.vue';
export default {
  components: {
    NotAvailableOverlay,
    Avatar,
  },
  props: ['match', 'selected'],
  computed: {
    candidateDisplayName() {
      if (this.match.candidate.anonymous) {
        return 'Anonym';
      } else {
        return `${this.match.candidate.firstName} ${this.match.candidate.lastName}`;
      }
    },
    unreadMessageCount() {
      return (
        this.match?.unseenMessagesCount[
          `${this.$store.state.user.currentUser?.id}`
        ] ?? 0
      );
    },
  },
  methods: {
    navigateTo() {
      this.$router.push({
        name: 'MessageMatchBusiness',
        params: { mid: this.match.id },
      });
    },
    formatDate,
    async archiveMatch(archive) {
      await this.$store.dispatch('business/archiveMatch', {
        matchId: this.match.id,
        archive,
      });
    },
  },
};
</script>
<style lang="sass" scoped>
@import "@/assets/style/main"

.chatCard
  position: relative
  border-top: solid $white 6px !important
  cursor: pointer
  transition: $transition
  margin-top: 0px !important
  padding-top: 8px !important
  margin-bottom: 15px !important
  @media only screen and (max-width: 959px)
    margin-top: 11px !important
    margin-bottom: 0px !important
    border-top: none !important
    padding-top: 15px !important
  &:hover
    box-shadow: 0 0 5px #c0c4e4 !important
    background-color: $accent-hover !important
    border-top-color: $accent !important
    transition: $transition

  &.hovered
    box-shadow: 0 0 5px #c0c4e4 !important
    background-color: $accent-hover !important
    border-top-color: $accent !important
  .unread-msg-chip
    position: absolute
    top: 5px
    right: 5px
    height: 28px
    width: 28px
    border-radius: 14px
    padding: 0
    text-align: center
    justify-content: center
    span
      color: #fff !important
      font-weight: 700
  .count-avatar
    z-index: 6
    position: absolute
    top: -11px
    right: -8px
    strong
      font-size: 0.8rem
      color: #fff !important
</style>
