<template lang="pug">
.Messages
  .div(v-if="matches && currentPosition")
    v-row.mt-6.pb-12.pb-md-4(v-if="matches.length > 0")
      v-col.pt-1.pt-md-0.messageList(cols="12" md="4")
        //- v-text-field.mb-4.search.no-underline(filled solo flat background-color="white" placeholder="Suche" hide-details  append-icon="mdi-magnify")
        
        //- TODO: refactor index (quick fix only)
        div.d-none.d-md-block(v-for='(match, index) in matches' :key="`${index}-web`" @click="selectMatchHandler(match.id, false)")
          ChatCard.mb-3(:match="match" :selected='isSelected(match)' )
        div.d-block.d-md-none.pb-1(v-for='(match, index) in matches' :key="`${index}-mobile`" @click="selectMatchHandler(match.id, true)")
          ChatCard(:match="match" :selected='isSelected(match)' )
      
      Chat.d-none.d-md-block(:matches='matches' :selectedMatch='selectedMatch')
      v-dialog(v-model='dialog' fullscreen hide-overlay scrollable)
        v-btn.absolute(icon @click="dialog = false" v-if="selectedMatch.opponentRemoved")
        ChatMobile(:matches='matches' :selectedMatch='selectedMatch' @closeModal="dialog = false" :visible="dialog")
    v-row(v-else)
      v-col.text-center.mt-12
        span Sie haben noch keine Nachrichten
  EmptyCardPosition(v-else text="Noch keine Stelle hinzugefügt")
</template>
<script>
import Vue from 'vue';

import Chat from '@/components/messages/Chat.vue';
import ChatMobile from '@/components/messages/ChatMobile';
import ChatCard from '@/components/business/matches/ChatCard.vue';
import EmptyCardPosition from '@/components/cards/EmptyCardPosition';
import { MatchesApi } from '@/api/modules/matches';

export default Vue.extend({
  components: { Chat, ChatCard, ChatMobile, EmptyCardPosition },
  data() {
    return {
      dialog: false,
      selectedMatchId: undefined,
    };
  },
  computed: {
    matches() {
      return this.$store.getters['business/matchesWithConversation'] ?? [];
    },
    selectedMatch() {
      return !this.selectedMatchId
        ? this.matches[0]
        : this.matches.find((match) => match.id === this.selectedMatchId);
    },
    currentPosition() {
      return this.$store.state.business.positions.find(
        (position) =>
          position.id === this.$store.state.business.selectedPositionId
      );
    },
  },
  beforeRouteLeave(_to, _from, next) {
    if (this.selectedMatchId)
      MatchesApi().markConversationForMatchActive(this.selectedMatchId, false);
    next();
  },
  async created() {
    if (this.$route.query.mid) {
      this.selectedMatchId = this.$route.query.mid;
    } else {
      this.selectedMatchId = this.selectedMatch?.id;
    }

    if (this.selectedMatchId && !this.selectedMatch?.startedCommunication) {
      await this.$store.dispatch(
        'business/markConversationStartForMatch',
        this.selectedMatchId
      );
    }
    this.$store.dispatch('business/fetchMatchesWithConversation');
    window.scrollTo(0, 0);
  },

  methods: {
    closeDialogHandler() {
      this.dialog = false;
    },

    selectMatchHandler(matchId, openModal) {
      this.dialog = openModal;
      if (this.selectedMatchId == matchId) return;
      this.selectedMatchId = matchId;
    },
    isSelected(match) {
      if (!this.selectedMatch || !match) return false;
      return match.id === this.selectedMatch.id;
    },
  },
  watch: {
    selectedMatchId(newMatchId, oldMatchId) {
      if (this.$route.query.mid !== newMatchId) {
        this.$router.replace({
          query: { ...this.$route.query, mid: newMatchId },
        });
      }
      this.$store.dispatch('messages/subscribeToMessagesForMatch', {
        matchId: newMatchId,
      });

      if (oldMatchId)
        MatchesApi().markConversationForMatchActive(oldMatchId, false);
    },
    matches() {
      if (!this.selectedMatchId) {
        this.selectedMatchId = this.matches[0]?.id;
      } else {
        const found = this.matches.find(
          (match) => match?.id === this.selectedMatchId
        );
        if (!found) {
          this.selectedMatchId = this.matches[0]?.id;
        }
      }
    },
  },
});
</script>

<style lang="sass" scoped>
.messageList
  height: calc(100vh - 200px)
  overflow-y: auto
  position: relative
@media only screen and (max-width: 1264px)
  .messageList
    height: calc(100vh - 220px)
@media only screen and (max-width: 959px)
  .messageList
    margin-top: -15px
    overflow-y: hidden
    height: 100%
// .messageList
//   // margin-top: -15px
//   height: calc(100vh - 200px)
//   overflow-y: auto
//   position: relative
// @media only screen and (max-width: 959px)
//   .messageList
//     overflow-y: hidden
//     margin-top: -15px
//     height: 100%
.search
  background: #fff
  border-radius: 10px
  border-bottom: none

.no-underline .v-input__control .v-input__slot::before, .no-underline .v-input__control .v-input__slot::after
  content: none !important

.v-dialog--fullscreen
  border-radius: 0
  margin: 0
  height: 100%
  position: fixed
  overflow-y: auto
  top: 100px
  left: 0
.absolute
  position: absolute
  top: 10px
  right: 10px
// @import "@/components/messages/ChatMessage"

// $message-input-height: 70px
// .name
//   font-weight: 700

// .status
//   color: $light-gray

// .messages-header-xs
//   position: relative
//   background-color: $background

// .messages-header-smAndUp
//   box-shadow: 0px 1px 10px -8px #000000
//   position: relative
//   z-index: 2
//   background-color: $white

// .matches-list
//   min-width: 270px
//   margin-top: 12px
//   background-color: $background
//   margin-left: 0
//   overflow-y: scroll
//   overflow-x: hidden
//   height: calc(63vh + #{$message-input-height})

// .messages-col
//   padding-left: 0
//   padding-bottom: 0

// .search-col
//   min-width: 270px

// .search-container
//   width: 100%
//   padding-bottom: 0
</style>
