<template lang="pug">
.Messages
  .div(v-if="!showOnboardingMessage")
    v-row.mt-6.pb-12.pb-md-4(v-if="matches.length > 0")
      v-col.pt-1.pt-md-0.messageList(cols="12" md="4")
        //- v-text-field.mb-4.search.no-underline(filled solo flat background-color="white" placeholder="Suche" hide-details  append-icon="mdi-magnify")
        div.d-none.d-md-block(v-for='(match, index) in matches' :key="`${match.id}desktop`" @click="selectMatchHandler(match.id, false)")
          ChatCard.mb-3(:match="match" :selected='isSelected(match)' )
        div.d-block.d-md-none.pb-1(v-for='(match, index) in matches' :key="`${match.id}_mobile`" @click="selectMatchHandler(match.id, true)")
          ChatCard(:match="match" :selected='isSelected(match)' )
      
      Chat.d-none.d-md-block(:matches='matches' :selectedMatch='selectedMatch')
      v-dialog(v-model='dialog' fullscreen scrollable hide-overlay)
        v-btn.absolute(icon @click="dialog = false" v-if="selectedMatch.opponentRemoved")
        ChatMobile(:matches='matches' :selectedMatch='selectedMatch' @closeModal="dialog = false" :visible="dialog")
    v-row(v-else)
      v-col.text-center.mt-12
        span Du hast noch keine Nachrichten
  StartOnboardingCard(v-else v-on="$listeners")
</template>
<script>
import Vue from 'vue';
import Chat from '@/components/messages/Chat.vue';
import ChatMobile from '@/components/messages/ChatMobile';
import ChatCard from '@/components/candidate/matches/ChatCard.vue';
import { MatchesApi } from '@/api/modules/matches';
import StartOnboardingCard from '@/components/cards/StartOnboardingCard';

export default Vue.extend({
  components: { Chat, ChatCard, ChatMobile, StartOnboardingCard },
  data() {
    return {
      dialog: false,
      selectedMatchId: undefined,
    };
  },
  computed: {
    matches() {
      return this.$store.getters['candidate/matchesWithConversation'];
    },
    selectedMatch() {
      return !this.selectedMatchId
        ? this.matches[0]
        : this.matches.find((match) => match?.id === this.selectedMatchId);
    },
    showOnboardingMessage() {
      return this.$store.getters['user/showOnboardingMessage'];
    },
  },
  beforeRouteLeave(_to, _from, next) {
    if (this.selectedMatchId)
      MatchesApi().markConversationForMatchActive(this.selectedMatchId, false);
    next();
  },
  async created() {
    this.selectedMatchId = this.$route.query.mid;

    if (this.$route.query.mid) {
      this.selectedMatchId = this.$route.query.mid;
    } else {
      this.selectedMatchId = this.selectedMatch?.id;
    }

    if (this.selectedMatchId) {
      await this.$store.dispatch(
        'candidate/markConversationStartForMatch',
        this.selectedMatchId
      );
    }
    this.$store.dispatch('candidate/fetchMatchesWithConversation');
    window.scrollTo(0, 0);
  },
  methods: {
    closeDialogHandler() {
      this.dialog = false;
    },
    selectMatchHandler(matchId, openModal) {
      this.dialog = openModal;
      if (this.selectedMatchId == matchId) return;
      this.selectedMatchId = matchId;
    },
    isSelected(match) {
      if (!this.selectedMatch || !match) return false;
      return match.id === this.selectedMatch.id;
    },
  },
  watch: {
    selectedMatchId(newMatchId, oldMatchId) {
      if (this.$route.query.mid !== newMatchId) {
        this.$router.replace({
          query: { ...this.$route.query, mid: newMatchId },
        });
      }
      this.$store.dispatch('messages/subscribeToMessagesForMatch', {
        matchId: newMatchId,
      });

      if (oldMatchId)
        MatchesApi().markConversationForMatchActive(oldMatchId, false);
    },
    matches() {
      if (!this.selectedMatchId) {
        this.selectedMatchId = this.matches[0]?.id;
      } else {
        const found = this.matches.find(
          (match) => match?.id === this.selectedMatchId
        );
        if (!found) {
          this.selectedMatchId = this.matches[0]?.id;
        }
      }
    },
  },
});
</script>

<style lang="sass" scoped>


.messageList
  height: calc(100vh - 200px)
  overflow-y: auto
  position: relative
@media only screen and (max-width: 1264px)
  .messageList
    height: calc(100vh - 220px)
@media only screen and (max-width: 959px)
  .messageList
    overflow-y: hidden
    margin-top: -15px
    height: 100%
.search
  background: #fff
  border-radius: 10px
  border-bottom: none

.no-underline .v-input__control .v-input__slot::before, .no-underline .v-input__control .v-input__slot::after
  content: none !important


.v-dialog--fullscreen
  border-radius: 0
  margin: 0
  height: 100%
  position: fixed
  overflow-y: auto
  top: 100px
  left: 0
.absolute
  position: absolute
  top: 10px
  right: 10px
</style>
